<template>
    <div class="hv-modal-login">
    <div id="checkout-modal-wrapper">
        <div class="modal fade checkout-modal" id="checkout" tabindex="-1" data-backdrop="static" ref="checkout_modal" role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="modal-title h3">{{ $translate("CeresCoconut::Template.checkoutModalTitle") }}</div>
                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" :aria-label="$translate('Ceres::Template.closeIcon')">&times;</button>
                    </div>
                    <div class="modal-body">
                      <div>
                        <div>{{ $translate("CeresCoconut::Template.checkoutModalLoginTitle") }}</div>
                        <a class="btn btn-block login-btn" data-testing="login-select" href="#login" data-toggle="modal" @click="createLoginModal();" :aria-label="$translate('Ceres::Template.login')" >
                            <i class="fa fa-user mr-1" aria-hidden="true"></i>
                            <span class="d-none d-sm-inline">{{ $translate("Ceres::Template.login") }}</span>
                        </a>
                        <div class="mt-3">{{ $translate("CeresCoconut::Template.checkoutModalGuestTitle") }}</div>
                        <button class="btn btn-block login-btn" data-dismiss="modal" aria-hidden="true" :aria-label="$translate('Ceres::Template.closeIcon')" >{{ $translate("CeresCoconut::Template.checkoutModalGuestBtnText") }}</button>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {

    props:
    {
        isModalOpen:
        {
          type: Boolean,
          default: false
        }
    },
    watch: { 
      	isModalOpen(newVal, oldVal) { 
          if(newVal) {
            this.openModal();
          }
        }
    },
    mounted() {
      if(this.isModalOpen) {
        this.openModal();
      }
      
      let modal = this.$refs.checkout_modal;
      $(modal).on('hidden.bs.modal', (e) => {
        this.$emit("close","true");
      })
    },
    methods: {
        openModal()
        {
          let element = this.$refs.checkout_modal;
          $(element).modal('show');
          sessionStorage.setItem("isCheckoutModalDone", true);
        },
        createLoginModal()
        {
            this.loadComponent("login-modal");
        },
        ...Vuex.mapActions([
            "loadComponent"
        ])
    }
}
</script>
