var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "hv-modal-login" }, [
    _c("div", { attrs: { id: "checkout-modal-wrapper" } }, [
      _c(
        "div",
        {
          ref: "checkout_modal",
          staticClass: "modal fade checkout-modal",
          attrs: {
            id: "checkout",
            tabindex: "-1",
            "data-backdrop": "static",
            role: "dialog",
          },
        },
        [
          _c("div", { staticClass: "modal-dialog" }, [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header" }, [
                _c("div", { staticClass: "modal-title h3" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$translate(
                        "CeresCoconut::Template.checkoutModalTitle"
                      )
                    )
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "close",
                    attrs: {
                      type: "button",
                      "data-dismiss": "modal",
                      "aria-hidden": "true",
                      "aria-label": _vm.$translate("Ceres::Template.closeIcon"),
                    },
                  },
                  [_vm._v("×")]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _c("div", [
                  _c("div", [
                    _vm._v(
                      _vm._s(
                        _vm.$translate(
                          "CeresCoconut::Template.checkoutModalLoginTitle"
                        )
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-block login-btn",
                      attrs: {
                        "data-testing": "login-select",
                        href: "#login",
                        "data-toggle": "modal",
                        "aria-label": _vm.$translate("Ceres::Template.login"),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.createLoginModal()
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-user mr-1",
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "d-none d-sm-inline" }, [
                        _vm._v(_vm._s(_vm.$translate("Ceres::Template.login"))),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "mt-3" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$translate(
                          "CeresCoconut::Template.checkoutModalGuestTitle"
                        )
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-block login-btn",
                      attrs: {
                        "data-dismiss": "modal",
                        "aria-hidden": "true",
                        "aria-label": _vm.$translate(
                          "Ceres::Template.closeIcon"
                        ),
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$translate(
                            "CeresCoconut::Template.checkoutModalGuestBtnText"
                          )
                        )
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }